var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passes = ref.passes;
return [_c('div',{staticClass:"section is-view",attrs:{"id":"is-time-add"}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-8 is-offset-2"},[_c('div',{staticClass:"card"},[_c('form',{attrs:{"action":"#"}},[_c('header',{staticClass:"card-header"},[_c('p',{staticClass:"card-header-title"},[_c('span',{staticClass:"column is-narrow"},[_vm._v(" Add time entry for ")]),_c('span',{staticClass:"column is-narrow"},[_c('b-field',[_c('b-select',{attrs:{"placeholder":"Find name","mode":"eager","icon":"user"},model:{value:(_vm.userName),callback:function ($$v) {_vm.userName=$$v},expression:"userName"}},_vm._l((_vm.userList),function(option){return _c('option',{key:option,domProps:{"value":option}},[_vm._v(" "+_vm._s(option)+" ")])}),0)],1)],1)])]),_c('div',{staticClass:"card-content"},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('ValidationProvider',{attrs:{"name":"kunde","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{attrs:{"label":"Choose Client","type":{'is-danger': errors[0], 'is-success': valid},"message":errors}},[_c('b-autocomplete',{key:"customer",ref:"kunde",attrs:{"expanded":"","open-on-focus":"","data":_vm.filteredKundenArray,"placeholder":"e.g. Forest Inc.","icon":"building"},on:{"select":function (option) { return _vm.selected = option; },"input":_vm.clearJobs},model:{value:(_vm.kunde),callback:function ($$v) {_vm.kunde=$$v},expression:"kunde"}},[_c('template',{slot:"empty"},[_vm._v("No client named "+_vm._s(_vm.kunde))])],2)],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"bereich","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{attrs:{"label":"Choose Area","type":{'is-danger': errors[0] && _vm.kunde, 'is-success': valid, 'is-unselectable': !_vm.kunde},"message":_vm.kunde?errors:''}},[_c('b-autocomplete',{key:"bereich",attrs:{"expanded":"","disabled":!_vm.kunde,"open-on-focus":"","data":_vm.filteredBereicheArray,"placeholder":"Find area","icon":"folder-open"},on:{"select":function (option) { return _vm.selected = option; }},model:{value:(_vm.bereich),callback:function ($$v) {_vm.bereich=$$v},expression:"bereich"}},[_c('template',{slot:"empty"},[_vm._v("No area named \""+_vm._s(_vm.bereich)+"\"")])],2)],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"job","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{attrs:{"label":"Choose Job","type":{'is-danger': errors[0] && _vm.kunde, 'is-success': valid},"message":_vm.kunde?errors:''}},[_c('b-autocomplete',{key:"job",attrs:{"expanded":"","disabled":!_vm.kunde,"open-on-focus":"","data":_vm.filteredJobsArray,"placeholder":"Find job","icon":"file-alt"},on:{"select":function (option) { return _vm.selected = option; }},model:{value:(_vm.job),callback:function ($$v) {_vm.job=$$v},expression:"job"}},[_c('template',{slot:"empty"},[_vm._v("No job named \""+_vm._s(_vm.job)+"\"")])],2)],1)]}}],null,true)})],1),_c('div',{staticClass:"column"},[_c('ValidationProvider',{attrs:{"name":"date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{attrs:{"label":"Choose Date Range","type":{'is-danger': errors[0], 'is-success': valid},"message":"Default: Today"}},[_c('b-datepicker',{key:"date",staticClass:"is-small",attrs:{"placeholder":"Click to select...","icon":"calendar","expanded":"","max-date":_vm.maxDate},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},[_c('div',{staticClass:"buttons is-right"},[_c('button',{staticClass:"button is-primary is-fullwidth",on:{"click":function($event){$event.preventDefault();_vm.date = new Date()}}},[_c('b-icon',{attrs:{"icon":"calendar-alt"}}),_c('span',[_vm._v("Today")])],1)])])],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"duration","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var _obj;

var errors = ref.errors;
var valid = ref.valid;return [_c('b-field',{attrs:{"label":"Add Duration","type":{'is-danger':errors[0], 'is-success': valid},"message":( _obj = {}, _obj[errors] = errors[0], _obj['Format: 01h 05m'] =  !errors[0], _obj )}},[_c('b-input',{directives:[{name:"cleave",rawName:"v-cleave",value:(_vm.masks.duration),expression:"masks.duration"}],key:"time-input",staticClass:"duration",attrs:{"placeholder":"01h 05m","value":_vm._f("durationFilter")(_vm.duration),"icon":"clock"},nativeOn:{"keyup":function($event){return _vm.onInput($event)}}})],1)]}}],null,true)}),_c('b-field',{attrs:{"label":"Note"}},[_c('b-input',{attrs:{"type":"textarea"},model:{value:(_vm.note),callback:function ($$v) {_vm.note=$$v},expression:"note"}})],1)],1)])]),_c('div',{staticClass:"card-footer is-right"},[_c('div',{staticClass:"field is-grouped is-grouped-right",staticStyle:{"margin-left":"auto"}},[_c('div',{staticClass:"control card-footer-item"},[_c('button',{staticClass:"button is-text",on:{"click":function($event){$event.preventDefault();return _vm.resetData($event)}}},[_vm._v("Reset")])]),_c('div',{staticClass:"control card-footer-item is-link"},[_c('button',{staticClass:"button is-primary",on:{"click":function($event){$event.preventDefault();return passes(_vm.addEntry)}}},[_vm._v("Submit")])])])])])])])])])])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }